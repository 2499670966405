import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    subtitle: React.CSSProperties;
    description: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    subtitle?: React.CSSProperties;
    description?: React.CSSProperties;
    bodyL?: React.CSSProperties;
    bodyM?: React.CSSProperties;
    bodyS?: React.CSSProperties;
  }

  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xlg: true;
    xl: true;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
    button: false;
    subtitle: true;
    bodyL: true;
    bodyM: true;
    bodyS: true;
    description: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    link: true;
    linkwhite: true;
    transparent: true;
  }
  interface ButtonPropsColorOverrides {
    light: true; //da rimuovere
    dark: true; //da rimuovere
  }
  interface ButtonPropsSizeOverrides {
    extraLarge: true;
  }
}

export const theme = createTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xlg", "xl"],
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1024,
      xlg: 1230,
      xl: 1400,
    },
  },
  spacing: (factor) => `${factor / 16}rem`,

  typography: {
    fontFamily: '"Work Sans", sans-serif',
    subtitle: {
      fontWeight: 400,
    },
  },

  palette: {
    common: {
      black: "#181B20",
      white: "#ffffff",
    },
    primary: {
      main: "#4F9E30",
      light: "#72C242",
      dark: "#4F9E30",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#F3F6FB",
      dark: "#1A1D2E",
      contrastText: "#ffffff",
    },
    success: {
      main: "#118000",
      light: "#72C242",
      dark: "#4F9E30",
      contrastText: "#D5EDC6",
    },
    warning: {
      main: "#804D00",
      light: "#FFBE16",
      dark: "#FF8C00",
      contrastText: "#FFE8AD",
    },
    error: {
      main: "#800000",
      light: "#FD0000",
      dark: "#CC0000",
      contrastText: "#FFCCCC",
    },
    info: {
      main: "#F3F6FB",
      light: "#6E8AD8",
      dark: "#0044B2",
      contrastText: "#E7EBF8",
    },
    grey: {
      50: "#F9F9FA",
      100: "#ECEDF0",
      200: "#DFE1E6",
      300: "#D0D3DA",
      400: "#C0C4CD",
      500: "#AEB3BF",
      600: "#99A0AF",
      700: "#828A9B",
      800: "#656D7F",
      900: "#3A3F4B",
    },
  },

  components: {
    MuiGrid: {
      defaultProps: {
        // The props to change the default for.
        columnSpacing: { xs: 8, md: 16, xl: 24 },
      },
    },
  },
});

export default theme;
